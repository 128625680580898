import store from "../store"

export default {
  install: function(Vue) {
    Vue.mixin({
      computed: {
        angemeldeterMitarbeiter() {
          return store.state.angemeldeterMitarbeiter;
        }
      }
    })
  }
}
