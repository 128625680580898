
export const SET_TEILENUMMER = "setTeilenummer";
export const SET_TEILEBEZEICHNUNG = "setTeilebezeichnung";
export const SET_VORGANGSTYP = "setVorgangstyp";
export const SET_VORGANGSUNTERTYP = "setVorgangsuntertyp";
export const SET_WERKZEUGNUMMER = "setWerkzeugnummer";
export const SET_WERKZEUGBEZEICHNUNG = "setWerkzeugbezeichnung";
export const SET_KOSTENSTELLE = "setKostenstelle";
export const SET_KONSTRUKTEUR = "setKontrukteur";
export const SET_TERMIN = "setTermin";

export const SET_ZEITPUNKT_FERTIGUNG_GESTARTET = "setZeitunktFertigungGestartet"
export const SET_ZEITPUNKT_KONSTRUKTION_GESTARTET = "setZeitunktKonstruktionGestartet"
export const SET_ZEITPUNKT_KONSTRUKTION_BEENDET = "setZeitunktKonstruktionBeendet"
export const SET_ZEITPUNKT_FERTIGUNG_BEENDET = "setZeitunktFertigungBeendet"
export const SET_NACHARBEITUNG_NOTWENDIG = "setNacharbeitungNotwendig"

export const SET_ZEIT_KONSTRUKTION_SOLL = "setZeitKonstruktionSoll"
export const SET_ZEIT_VORFERTIGUNG_SOLL = "setZeitVorfertigungSoll"
export const SET_ZEIT_PROGRAMMIERUNG_SOLL = "setZeitProgrammierungSoll"
export const SET_ZEIT_FERTIGUNG_SOLL = "setZeitFertigungSoll"

export const SET_ZEIT_MASCHINE_SOLL = "setZeitMaschineSoll"
export const SET_ZEIT_WERKBANK_SOLL = "setZeitWerkbankSoll"
export const SET_ZEIT_WERKZEUGBAU_SOLL = "setZeitWerkzeugbauSoll"

export const SET_KONSTRUKTION_EXTERN = "setKonstruktionExtern"
export const SET_FERTIGUNG_EXTERN = "setFertigungExtern"


export const SET_ZEITBUCHUNGEN = "setZeitbuchungen"
export const REMOVE_ZEITBUCHUNG = "removeZeitbuchung"
export const SET_PROGRAMMZEITEN = "setProgrammzeiten"
export const REMOVE_PROGRAMMZEIT = "removeProgrammzeit"
export const SET_FREMDLEISTUNGEN = "setFremdleistungen"
export const REMOVE_FREMDLEISTUNG = "removeFremdleistung"

export const SET_VORGANG = "setVorgang"
export const CLEAR_VORGANG = "clearVorgang"

export const ADD_ZEICHNUNG = "addZeichnung"
export const REMOVE_ZEICHNUNG = "removeZeichnung"
export const ADD_BILD = "addBild"
export const REMOVE_BILD = "removeBild"
