import {
  SET_MASCHINE,
  SET_WARTENDE_PROGRAMME,
  SET_LAUFENDES_PROGRAMM,
  SET_ABGESCHLOSSENE_PROGRAMME,
  SET_VORGAENGE,
  CLEAR_MASCHINE
} from "./mutations.type"
import {
  LADE_PROGRAMME
} from "./actions.type"

import maschine from "../../api/maschine"


export default {
  namespaced: true,
  state: {
    id: null,
    maschine: null,
    laufendes_programm: null,
    wartende_programme: [],
    abgeschlossene_programme: [],
    vorgaenge: []
  },
  mutations: {
    [SET_MASCHINE](state, val) {
      state.maschine = val;
      state.id = val.id;
    },
    [SET_LAUFENDES_PROGRAMM](state, val) {
      state.laufendes_programm = val;
    },
    [SET_WARTENDE_PROGRAMME](state, val) {
      state.wartende_programme = val;
    },
    [SET_ABGESCHLOSSENE_PROGRAMME](state, val) {
      state.abgeschlossene_programme = val;
    },
    [SET_VORGAENGE](state,val) {
      state.vorgaenge = val;
    },
    [CLEAR_MASCHINE](state) {
      state.id = null,
      state.maschine = null,
      state.laufendes_programm = null,
      state.wartende_programme = [],
      state.abgeschlossene_programme = [],
      state.vorgaenge = []
    }
  },
  actions: {
    async [LADE_PROGRAMME](context) {
      let response = await maschine.getProgramme(context.state.id);
      context.commit(SET_WARTENDE_PROGRAMME, response.data.wartende_programme);
      context.commit(SET_LAUFENDES_PROGRAMM, response.data.laufendes_programm);
      context.commit(SET_ABGESCHLOSSENE_PROGRAMME, response.data.kuerzlich_abgeschlossene_programme);
    },
  }
}
