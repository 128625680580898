export default {
  insertUrlParam: function (key, value, replace) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();

    if (replace && window.history.replaceState) {
      window.history.replaceState({ path: newurl }, '', newurl);
    } else if (window.history.pushState) {
      window.history.pushState({ path: newurl }, '', newurl);
    }
  },
  removeUrlParam: function (key, replace) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(key);
    let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();

    if (replace && window.history.replaceState) {
      window.history.replaceState({ path: newurl }, '', newurl);
    } else if (window.history.pushState) {
      window.history.pushState({ path: newurl }, '', newurl);
    }
  },
}
