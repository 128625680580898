<template>
  <b-navbar
    type="dark"
    :variant="
      angemeldeterMitarbeiter && angemeldeterMitarbeiter.admin_user
        ? 'danger'
        : 'cosmos'
    "
  >
    <b-navbar-brand v-if="angemeldeterMitarbeiter" :to="{ name: 'index' }">
      <img
        src="../assets/img/logo.svg"
        height="60"
        class="d-inline-block align-top"
        alt=""
        loading="lazy"
      />
    </b-navbar-brand>
    <b-navbar-brand v-else>
      <img
        src="../assets/img/logo.svg"
        height="60"
        class="d-inline-block align-top"
        alt=""
        loading="lazy"
      />
    </b-navbar-brand>

    <b-navbar-nav v-if="angemeldeterMitarbeiter" class="ml-auto" align="right">
      <b-nav-item right>
        <div class="my-2 my-lg-0">
          <div class="navbar-icon" id="popover-user">
            <i class="ifr if-customer text-white user-icon"></i>
            <div class="text-white">{{ angemeldeterMitarbeiter | name }}</div>
          </div>
          <b-popover
            target="popover-user"
            triggers="click"
            placement="bottom"
            ref="userPopover"
          >
            <div class="text-left p-3">
              <div class="popover-item" @click="meineZeiterfassungen">
                Meine Zeiterfassung
              </div>
              <hr />
              <div class="popover-item" @click="umloggen">Umloggen</div>
              <!--<hr />
                <div  class="popover-item" @click="passwortAendern">Passwort ändern</div>-->
              <hr />
              <div class="popover-item" @click="logout">Logout</div>
            </div>
          </b-popover>
        </div>
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
  import { LOGOUT } from "@/store/actions.type";

  export default {
    name: "Navbar",
    components: {},
    methods: {
      umloggen() {
        this.$refs.userPopover.$emit("close");
        this.$emit("open-umloggen");
      },
      passwortAendern() {
        this.$refs.userPopover.$emit("close");
        this.$emit("open-passwort");
      },
      meineZeiterfassungen() {
        this.$refs.userPopover.$emit("close");
        this.$router.push({ name: "meinezeiterfassung" });
      },
      async logout() {
        this.$refs.userPopover.$emit("close");
        try {
          await this.$store.dispatch(LOGOUT);
          this.$router.push({ name: "login" });
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../node_modules/@bx/batix-industrial-ui-framework/scss/_colors.scss";

  hr {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  .navbar {
    position: sticky;
    top: 0px;
    z-index: 5;
  }

  .user-icon {
    font-size: 40px;
  }

  .navbar-icon {
    text-align: center;
    font-size: 10px;
  }

  .popover-item {
    color: $indicatorNeutral;
    font-family: Khand;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      color: $elements5oceanBlue;
      text-decoration: none;
    }
  }
</style>
