const konstruktion = "1756A20FBF2"
const vorfertigung = "17A65F794E5"
const programmierung = "1756A226DD1"
const fertigung = "1756A22852C"
const werkbank = "1756A22A589"

export default {
  konstruktion: konstruktion,
  vorfertigung: vorfertigung,
  programmierung: programmierung,
  fertigung: fertigung,
  werkbank: werkbank,
  iconClass: (id) => {
    switch (id) {
      case konstruktion:
        return "ifr if-process-empty";
      case vorfertigung:
        return "ifr if-tools";
      case programmierung:
        return "ifr if-process-one";
      case fertigung:
        return "ifr if-process-two";
      case werkbank:
        return "ifr if-process-full";
      default:
        return "ifr if-time-slot"
    }
  }
}
