import api from "./index"

export default {
  getProgramme(maschine) {
    return api.get(`programm/maschine/${maschine}`)
  },
  erstelleBedienerzeit(maschineId, bedienerzeit) {
    return api.post(`maschine/${maschineId}/bedienerzeit`, bedienerzeit)
  },
  aendereBedienerzeit(maschineId, bedienerzeitId, bedienerzeit) {
    return api.put(`maschine/${maschineId}/bedienerzeit/${bedienerzeitId}`, bedienerzeit)
  }
}
