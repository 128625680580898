<template>
  <b-modal no-close-on-backdrop ref="modal" size="lg" centered title="Umloggen">
    <div class="row">
      <div class="col-12 d-none">
        <form @submit.prevent="umloggen">
          <div class="form-group" :class="{ 'mb-0': error !== null }">
            <input
              v-model.trim="personalnummer"
              id="umloggen-personalnummer-input"
              class="form-control"
              :class="{ 'is-invalid': error !== null }"
              placeholder=" "
            />
            <label>Personalnummer</label>
            <div class="invalid-feedback">
              {{ error }}
            </div>
          </div>
        </form>
      </div>
      <div class="col-12">
        <div class="row">
          <div
            v-for="w in werker"
            :key="w.id"
            class="col-md-6 clickable"
            @click="umloggenMitId(w.id)"
          >
            <mitarbeiter-media-badge :mitarbeiter="w"></mitarbeiter-media-badge>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="row w-100">
        <div class="col-12 text-center">
          <button class="btn btn-gray mr-3" @click="hide">Abbrechen</button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import modal from "../../mixins/modal";
  import axios from "axios";
  import { SET_ANGEMELDETER_MITARBEITER } from "../../store/mutations.type";
  import server from "../../server";
  import MitarbeiterMediaBadge from "@/components/MitarbeiterMediaBadge";

  export default {
    name: "Umloggenmodal",
    components: {
      MitarbeiterMediaBadge,
    },
    mixins: [modal],
    data() {
      return {
        personalnummer: null,
        error: null,
      };
    },
    watch: {
      shown(val) {
        if (!val) {
          this.personalnummer = null;
          this.error = null;
        } else {
          setTimeout(() => {
            document.getElementById("umloggen-personalnummer-input").focus();
          }, 200);
        }
      },
    },
    computed: {
      umloggenUrl() {
        if (process.env.NODE_ENV === "development") {
          return `${server.url}/umloggen/`;
        } else {
          return "/umloggen/";
        }
      },
      werker() {
        return this.$store.getters.werker;
      },
    },
    methods: {
      umloggen() {
        this.error = null;
        axios
          .post(
            this.umloggenUrl,
            { personalnummer: this.personalnummer },
            { withCredentials: true }
          )
          .then((response) => {
            this.error = null;
            this.umgeloggt(response.data);
          })
          .catch((e) => {
            this.error = e.response ? e.response.data : "Umloggen Fehlgeschlagen";
          });
      },
      umloggenMitId(id) {
        this.error = null;
        axios
          .post(this.umloggenUrl, { id: id }, { withCredentials: true })
          .then((response) => {
            this.error = null;
            this.umgeloggt(response.data);
          })
          .catch((e) => {
            this.error = e.response ? e.response.data : "Umloggen Fehlgeschlagen";
          });
      },
      umgeloggt(neuerMitarbeiter) {
        this.hide();
        this.$store.commit(SET_ANGEMELDETER_MITARBEITER, neuerMitarbeiter);
        this.$emit("umgeloggt", neuerMitarbeiter);
      },
    },
  };
</script>

<style>
</style>
