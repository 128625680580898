export const SET_KOSTENSTELLEN = "setKostenstellen";
export const SET_VORGANGSTYPEN = "setVorgangstypen";
export const SET_VORGANGSSTATI = "setVorgangsstati";
export const SET_MASCHINEN = "setMaschinen";
export const SET_MASCHINENTYPEN = "setMaschinentypen";
export const SET_VORGANGSSCHRITTE = "setVorgangsschritte";
export const SET_MITARBEITER = "setMitarbeiter";
export const SET_ANGEMELDETER_MITARBEITER = "setAngemeldeterMitarbeiter";
export const SET_STAMMDATEN_GELADEN = "setstammdatenGeladen";
export const SET_BERECHTIGUNGEN = "setBerechtigungen";
export const UPDATE_KOSTENSTELLE = "updateKostenstelle";