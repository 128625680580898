import api from "./index";
import _ from "lodash";

export default {

    /**
     *
     * @param {Number} page Angefragte Seite (0-Basiert)
     * @param {Number} count Anzahl der Angeforderten Vorgänge
     * @param {String} typ Typ des Vorgangs
     * @param {String} status Status des Vorgangs
     * @param {String} teilenummer Teilenummer oder teil der Teilenumemr (suche)
     * @param {String} teilebezeichnung Teilebezeichnung oder teil der Teilebezeichnung (suche)
     * @param {String} kostenstelle ID der Kostenstelle
     * @param {String} termin Termin an dem Tag
     * @param {String} schritt name des Schrittes
     * @returns
     */
    get: (page, count, vorgangstyp, status, teilenummer, teilebezeichnung, kostenstelle, termin, schritt, extern, konstrukteur) => {
        let p = page || 0;
        let c = count || 6;
        return api.get(`vorgang${schritt ? '/' + schritt : ''}`,
        {
          params: {
            page: p,
            count: c,
            vorgangstyp,
            status: status && status.length > 0 ? _.join(status, ",") : null,
            teilenummer,
            teilebezeichnung,
            kostenstelle,
            termin,
            extern,
            konstrukteur
          }
        }
      )
    },
    getById: (id, schritt) => {
        return api.get(`vorgang/${id}`, { params: { schritt}})
    },
    post(vorgangstyp, kostenstelle, vorgangsuntertyp, kundenauftrag, teilenummer, teilebezeichnung, werkzeugnummer, werkzeugbezeichnung, termin, konstrukeur) {
      let json = {
        teilenummer: teilenummer,
        teilebezeichnung: teilebezeichnung,
        werkzeugnummer: werkzeugnummer,
        werkzeugbezeichnung: werkzeugbezeichnung,
        kundenauftrag: kundenauftrag,
        termin: termin,
        vorgangstypId: vorgangstyp,
        vorgangsuntertypId: vorgangsuntertyp,
        kostenstelleId: kostenstelle,
        konstrukteurId: konstrukeur,
      }

      return api.post('vorgang/', json)
    },
    put(vorgangId, vorgangstyp, kostenstelle, vorgangsuntertyp, kundenauftrag, teilenummer, teilebezeichnung, werkzeugnummer, werkzeugbezeichnung, termin, konstrukeur) {
      let json = {
        teilenummer: teilenummer,
        teilebezeichnung: teilebezeichnung,
        werkzeugnummer: werkzeugnummer,
        werkzeugbezeichnung: werkzeugbezeichnung,
        kundenauftrag: kundenauftrag,
        termin: termin,
        vorgangstypId: vorgangstyp,
        vorgangsuntertypId: vorgangsuntertyp,
        kostenstelleId: kostenstelle,
        konstrukteurId: konstrukeur,
      }

      return api.put(`vorgang/${vorgangId}`, json)
    },
    delete(vorgangId) {
      return api.delete(`vorgang/${vorgangId}`)
    },
    kalkulationSpeichern(
      id,
      zeit_konstruktion_soll,
      zeit_vorfertigung_soll,
      zeit_programmierung_soll,
      zeit_fertigung_soll,
      zeit_maschine_soll,
      zeit_werkbank_soll,
      konstruktion_extern,
      fertigung_extern
      ) {
      return api.patch(`vorgang/${id}/kalkulation`, {
        zeit_konstruktion_soll,
        zeit_programmierung_soll,
        zeit_fertigung_soll,
        zeit_vorfertigung_soll,
        zeit_maschine_soll,
        zeit_werkbank_soll,
        konstruktion_extern,
        fertigung_extern
      })
    },
    startekonstrukion(id) {
      return api.patch(`vorgang/${id}/konstruktion/starten`)
    },
    beendekonstrukion(id) {
      return api.patch(`vorgang/${id}/konstruktion/beenden`)
    },
    starteFertigung(id) {
      return api.patch(`vorgang/${id}/fertigung/starten`)
    },
    beendeFertigung(id) {
      return api.patch(`vorgang/${id}/fertigung/beenden`)
    },
    erprobungBestanden(id) {
      return api.patch(`vorgang/${id}/erprobung_bestanden`)
    },
    nachbesserungNotwendig(id) {
      return api.patch(`vorgang/${id}/nacharbeitung_notwendig`)
    },
    archivieren(id) {
      return api.patch(`vorgang/${id}/archivieren`)
    },
    erstelleZeitbuchung(vorgangId, zeitbuchung) {
      return api.post(`vorgang/${vorgangId}/zeiterfassung`, zeitbuchung)
    },
    aendereZeitbuchung(vorgangId, zeitbuchungId, zeitbuchung) {
      return api.put(`vorgang/${vorgangId}/zeiterfassung/${zeitbuchungId}`, zeitbuchung)
    },
    erstelleProgrammzeit(vorgangId, programmzeit) {
      return api.post(`vorgang/${vorgangId}/programmzeit`, programmzeit)
    },
    aendereProgrammzeit(vorgangId, programmzeitId, programmzeit) {
      return api.put(`vorgang/${vorgangId}/programmzeit/${programmzeitId}`, programmzeit)
    },
    erstelleFremdleistung(vorgangId, fremdleistung) {
      return api.post(`vorgang/${vorgangId}/fremdleistung`, fremdleistung)
    },
    aendereFremdleistung(vorgangId, fremdleistungId, fremdleistung) {
      return api.put(`vorgang/${vorgangId}/fremdleistung/${fremdleistungId}`, fremdleistung)
    },
    getStatistik(){
      return api.get("vorgang/statistik")
    },
    speichereBild(vorgangId, picturepayload) {
      let json = {
        payload: picturepayload
      }
      return api.post(`bild/vorgang/${vorgangId}/base64`, json)
    },
    loescheBild(bildId) {
      return api.delete(`bild/${bildId}`)
    }
}
