import organisationsbereiche from "./../static/organisationsbereiche";

export default {
  istKontrukteur: function(mitarbeiter) {
    return mitarbeiter.organisationsbereiche.some(o => o.id === organisationsbereiche.konstruktion)
  },
  anzeigeName: (mitarbeiter) => {
    if(mitarbeiter.namenszusatz)
      return `${mitarbeiter.namenszusatz} ${mitarbeiter.vorname} ${mitarbeiter.name}`;
    else
      return `${mitarbeiter.vorname} ${mitarbeiter.name}`;
  }
}
