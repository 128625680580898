<template>
  <div v-if="stammdatenGeladen || $route.name === 'login'" id="app">
    <navbar @open-umloggen="openUmloggenModal" @open-passwort="openPasswortModal"></navbar>
    <router-view />
    <umloggen-modal id="umloggen-modal" ref="umloggenModal" @umgeloggt="ladeBerechtigungen"></umloggen-modal>
    <passwort-modal id="passwort-modal" ref="passwortModal" @password-changed="$refs.messageboxPasswordChanged.show()"></passwort-modal>
    <messagebox
      id="messagebox-passwort-changed"
      ref="messageboxPasswordChanged"
      ok
      :cancel="false"
      text="Sie haben Ihr Passwort erfolgreich geändert!"
    ></messagebox>
    <notifications position="top right"></notifications>
  </div>
</template>

<script>

import store from "./store";

import {
  LADE_KATEGORIEN,
  LADE_BERECHTIGUNGEN
} from "./store/actions.type";

import Navbar from '@/components/Navbar'
import UmloggenModal from '@/components/modals/UmloggenModal'
import PasswortModal from '@/components/modals/PasswortModal'
import Messagebox from '@/components/modals/Messagebox'

export default {
  name: "App",
  store,
  components: {
    Navbar,
    UmloggenModal,
    PasswortModal,
    Messagebox
  },
  computed: {
    stammdatenGeladen() {
      return this.$store.state.stammdatenGeladen;
    },
  },
  async created() {
    if(this.$store.state.angemeldeterMitarbeiter)
      await this.$store.dispatch(LADE_KATEGORIEN)
  },
  methods: {
    openUmloggenModal() {
      this.$refs.umloggenModal.show()
    },
    openPasswortModal() {
      this.$refs.passwortModal.show()
    },
    async ladeBerechtigungen() {
      await this.$store.dispatch(LADE_BERECHTIGUNGEN, this.$store.state.angemeldeterMitarbeiter.id)
    }
  }
}
</script>

<style lang="scss">
@import "./scss/app.scss";

#app {
  .content {
    margin-top: 86px;
  }
}
</style>
