import url from "../util/url"

export default {
  install: function(Vue) {
    Vue.mixin({
      methods: {
        getBooleanParameter(name, def = null) {
          return this.$route.query[name] ? JSON.parse(this.$route.query[name]) : def
        },
        getStringParameter(name, def = null) {
          return this.$route.query[name] || def
        },
        getIntParameter(name, def = null) {
          return this.$route.query[name] ? parseInt(this.$route.query[name]) : def
        },
        setParameter(name, value, replace = false) {
          url.insertUrlParam(name, value, replace)
        },
        removeParameter(name, replace = false) {
          url.removeUrlParam(name, replace)
        }
      },
    })
  }
}
