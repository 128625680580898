import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { LADE_USER } from "./store/actions.type";

import vSelect from 'vue-select';
import vCalendar from 'v-calendar';
import numeral from 'numeral';
import 'numeral/locales';
import 'viewerjs/dist/viewer.css';
import Viewer from 'v-viewer';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import MessageBox from "./components/modals/Messagebox";
import ActionButton from "./components/buttons/Actionbutton";

import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import Notifications from "vue-notification";

import "./util/filter";

import Berechtigungen from "./plugin/Berechtigungen";
import AngemeldeterMitarbeiter from "./plugin/AngemeldeterMitarbeiter";
import Url from "./plugin/Url";
import BatixIndustrialUiFramework from "@bx/batix-industrial-ui-framework";

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = ".";
numeral.localeData("de").delimiters.decimal = ",";

Vue.config.productionTip = false;
Vue.component('v-select', vSelect);
Vue.component('message-box', MessageBox);
Vue.component('action-button', ActionButton);

Vue.use(vCalendar, {});
Vue.use(Viewer)
Vue.use(Notifications)
Vue.use(Berechtigungen)
Vue.use(AngemeldeterMitarbeiter)
Vue.use(Url)
Vue.use(BatixIndustrialUiFramework)

store.dispatch(LADE_USER).finally(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
