import api from "./index"

export default {
  get(maschine) {
    return api.get(`programm/maschine/${maschine}`)
  },
  getById(id) {
    return api.get(`programm/${id}`)
  },
  starten(id) {
    return api.patch(`programm/${id}/starten`)
  },
  erstelleMaschinenzeit(programmId, maschinenzeit) {
    return api.post(`programm/${programmId}/maschinenzeit`, maschinenzeit)
  },
  aendereMaschinenzeit(programmId, maschinenzeitId, maschinenzeit) {
    return api.put(`programm/${programmId}/maschinenzeit/${maschinenzeitId}`, maschinenzeit)
  },
  erstelleBedienerzeit(programmId, bedienerzeit) {
    return api.post(`programm/${programmId}/bedienerzeit`, bedienerzeit)
  },
  aendereBedienerzeit(programmId, bedienerzeitId, bedienerzeit) {
    return api.put(`programm/${programmId}/bedienerzeit/${bedienerzeitId}`, bedienerzeit)
  },
  delete(id) {
    return api.delete(`programm/${id}`)
  }
}
