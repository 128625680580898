export const SET_PROGRAMM = "setProgramm";
export const CLEAR_PROGRAMM = "slearProgramm";
export const SET_GESTARTET = "setGestartet";
export const SET_BEENDET = "setBeendet";
export const SET_MASCHINENZEITEN = "setMaschinenzeiten";
export const SET_BEDIENERZEITEN = "setBedienerzeiten";
export const ADD_BILD = "addBild"
export const REMOVE_BILD = "removeBild"
export const REMOVE_BEDIENERZEIT = "removeBedienerzeit"
export const REMOVE_MASCHINENZEIT = "removeMaschinenzeit"
