import { SET_HEIGHT, SET_WIDTH } from "@/store/window/mutations.type";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shown: false
    }
  },
  methods: {
    show() {
      this.$store.commit(`window/${SET_HEIGHT}`, window.innerHeight)
      this.$store.commit(`window/${SET_WIDTH}`, window.innerWidth)

      this.shown = true;
      this.$refs.modal.show()
      this.$emit("show")
    },
    hide() {
      this.shown = false;
      this.$refs.modal.hide()
      this.$emit("hide")
    }
  }
};
