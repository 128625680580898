import api from "./index"

export default {
  get: () => {
    return api.get("mitarbeiter")
  },
  getAngemeldetenMitarbeiter() {
    return api.get("mitarbeiter/angemeldet")
  },
  getKontrukteurAuslastung(id) {
    return api.get(`mitarbeiter/${id}/auslastung`)
  },
  getAvatar(id) {
    return api.get(`mitarbeiter/${id}/avatar`)
  }
}
