import Vue from "vue";
import numeral from 'numeral';
// eslint-disable-next-line no-unused-vars
import { DateTime } from 'luxon'

Vue.filter("name", (value) => {
  if (!value || !value.name) return ""
  let str = "";
  if(value.namenszusatz) str += `${value.namenszusatz} `;
  str += `${value.vorname} ${value.name}`
  return str
});

Vue.filter("minutenStunden", (value) => {
  if(!value) return '0';
  return `${numeral(value / 60).format("0.00")}`;
});

Vue.filter("colorClassType", (value) => {
  if(value === 'Änderung') return 'changes';
  if(value === 'Neuteil') return 'new';
  if(value === 'Vorrichtung') return 'vorrichtung';
  if(value === 'Reparatur') return 'reparatur';
  return '$elements3lightBlue';
})


Vue.filter("tsToDateString", value => {
  if(!value) return ''
  return DateTime.fromMillis(value).setLocale('de').toFormat('dd.MM.yyyy');
})

Vue.filter("price", value => {
  if (isNaN(value) || !isFinite(value)) return "";
  return numeral(value).format("0,0.00");
})
