<template>
  <div class="media bg-super-light">
    <img v-if="mitarbeiter.avatar" :src="avatarUri" alt="" width="100%" class="d-block" />
    <img v-if="!mitarbeiter.avatar" src="../assets/img/default-user-image.png" class="mr-3" />
    <div class="media-body">
      <h5 class="mt-2 font-khand">{{ mitarbeiter | name }}</h5>
    </div>
</div>
</template>

<script>

import server from "../server";

export default {
  name: "MitarbeiterMediaBadge",
  props: {
    mitarbeiter: {
      type: Object,
      required: true
    }
  },
  computed: {
    avatarUri() {
      return `${server.url}${this.mitarbeiter.avatar}`;
    }
  }
}
</script>

<style lang="scss" scoped>

.media {
  img {
    width: 64px;
    height: 64px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  border: 1px solid gray;
  border-radius: 10px;

  margin-bottom: 1rem;
}

</style>
