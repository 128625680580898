import {
  SET_HEIGHT,
  SET_WIDTH
} from "./mutations.type"

export default {
  namespaced: true,
  state: {
    height: 0,
    width: 0,
  },
  mutations: {
    [SET_HEIGHT](state, height) { state.height = height },
    [SET_WIDTH](state, width) { state.width = width; },
  },
  getters: {

  }
}
