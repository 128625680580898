<template>
  <b-modal no-close-on-backdrop ref="modal" size="lg" centered hide-header>
    <template #modal-header>
      <h4>Passwort ändern</h4>
    </template>
    <div class="row">
      <div class="col-12">
        <form @submit.prevent="passwortAendern">
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model.trim="passwordOld"
                  id="passwort-password-old"
                  type="password"
                  class="form-control"
                  placeholder=" "
                />
                <label>Altes Passwort</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model.trim="passwordNew"
                  id="passwort-password-new"
                  class="form-control"
                  type="password"
                  placeholder=" "
                />
                <label>Neues Passwort</label>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <input
                  v-model.trim="passwordNew2"
                  id="passwort-password-new2"
                  class="form-control"
                  type="password"
                  placeholder=" "
                />
                <label>Passwort wiederholen</label>
              </div>
            </div>
            <div class="col-12">
              <div class="text-danger fs-12">
                {{ error }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <template #modal-footer>
      <div class="row w-100">
        <div class="col-12 text-center">
          <button class="btn btn-gray mr-3" @click="hide">Abbrechen</button>
          <button class="btn btn-success" @click="passwortAendern">
            Speichern
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import modal from "../../mixins/modal";
  import axios from "axios";
  import server from "../../server";

  export default {
    name: "Passwortmodal",
    mixins: [modal],
    data() {
      return {
        passwordOld: null,
        passwordNew: null,
        passwordNew2: null,
        error: null,
      };
    },
    watch: {
      shown(val) {
        if (!val) {
          this.personalnummer = null;
          this.passwordNew = null;
          this.passwordNew2 = null;
          this.error = null;
        }
      },
    },
    computed: {
      passwortUrl() {
        if (process.env.NODE_ENV === "development") {
          return `${server.url}/password/`;
        } else {
          return "/password/";
        }
      },
    },
    methods: {
      passwortAendern() {
        this.error = null;
        const params = new URLSearchParams();
        params.append("password-old", this.passwordOld);
        params.append("password-new", this.passwordNew);
        params.append("password-new2", this.passwordNew2);

        axios
          .post(this.passwortUrl, params, { withCredentials: true })
          .then(() => {
            this.error = null;
            this.passwortGeaendert();
          })
          .catch((e) => {
            this.error = e.response
              ? e.response.data
              : "Passwort Ändern Fehlgeschlagen";
          });
      },
      passwortGeaendert() {
        this.hide();
        this.$emit("password-changed");
      },
    },
  };
</script>

<style>
</style>
