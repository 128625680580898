import schritte from "../models/vorgangsschritt";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/vorgaenge",
    name: "vorgaenge",
    component: () => import("../views/desktop/Vorgaenge.vue")
  },
  {
    path: "/auslastungsplan",
    name: "auslastungsplan",
    component: () => import("../views/desktop/Auslastungsplan.vue")
  },
  {
    path: "/freiezeiterfassung",
    name: "freiezeiterfassung",
    component: () => import("../views/desktop/FreieZeiterfassung")
  },
  {
    path: "/vorgaenge/:schritt",
    name: "vorgaenge.schritt",
    component: () => import("../views/desktop/Vorgaenge.vue"),
    props: true
  },
  {
    path: "/vorgang/:id/edit",
    name: "vorgang.edit",
    component: () => import("../views/desktop/Vorgang.vue"),
    props: true
  },
  {
    path: "/konfiguration/stundensaetze",
    name: "stundensaetze",
    component: () => import("../views/desktop/konfiguration/Stundensaetze.vue")
  },
  {
    path: "/konfiguration/stundensaetze/:id",
    name: "stundensaetze.id",
    props: true,
    component: () => import("../views/desktop/konfiguration/Stundensaetze.vue")
  },
  {
    path: "/maschinenuebersicht/",
    name: "maschinenuebersicht",
    component: () => import("../views/mobile/Maschinenuebersicht.vue"),
  },
  {
    path: "/maschine/:id",
    name: "maschine",
    component: () => import("../views/mobile/Maschine.vue"),
    props: true
  },
  {
    path: "/mobile/programm/:id",
    name: "mobile.programm",
    component: () => import("../views/mobile/Programm.vue"),
    props: true
  },
  {
    path: "/mobile/programm/:id/:anzeige",
    name: "mobile.programm.anzeige",
    component: () => import("../views/mobile/Programm.vue"),
    props: route => {
      const a = parseInt(route.params.anzeige, 10)
      return {
        id: route.params.id,
        anzeige: isNaN(a) ? 0 : a
      }
    }
  },

  {
    path: "/mobile/vorgang/:id/:schritt/",
    name: "mobile.vorgang.schritt",
    component: () => import("../views/mobile/Vorgang.vue"),
    props: true
  },
  {
    path: "/mobile/vorgang/:id/:schritt/:anzeige/",
    name: "mobile.vorgang.schritt.anzeige",
    component: () => import("../views/mobile/Vorgang.vue"),
    props: route => {
      const a = parseInt(route.params.anzeige, 10)
      return {
        id: route.params.id,
        schritt: route.params.schritt,
        anzeige: isNaN(a) ? 0 : a
      }
    }
  },
  {
    path: "/werkbank",
    name: "werkbank",
    component: () => import("../views/mobile/Vorgaenge.vue"),
    props: () => {
      return {
        name: "Werkbank",
        schritt: "fertigung",
        schrittId: schritte.werkbank
      }
    }
  },
  {
    path: "/vorfertigung",
    name: "vorfertigung",
    component: () => import("../views/mobile/Vorgaenge.vue"),
    props: () => {
      return {
        name: "Vorfertigung",
        schritt: "vorfertigung",
        schrittId: schritte.vorfertigung
      }
    }
  },
  {
    path: "/meinezeiterfassung",
    name: "meinezeiterfassung",
    component: () => import("../views/desktop/Zeiterfassungen.vue"),
    props: () => {
      return {
        eigene: true
      }
    }
  },
  {
    path: "/zeiterfassungen",
    name: "zeiterfassungen",
    component: () => import("../views/desktop/Zeiterfassungen.vue")
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () => import("../views/Errors/Forbidden.vue")
  },
  {
    path: "/",
    name: "index",
    component: () => import("../views/Index.vue")
  },
  {
    path: "*",
    name: "notfound",
    component: () => import("../views/Index.vue")
  }
]
