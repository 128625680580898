<template>
  <b-modal no-close-on-backdrop ref="modal" :size="size" centered hide-header>
    <template #modal-header>
      <slot name="header" />
    </template>
    <slot></slot>
    <p v-if="text !== null">{{ text }}</p>
    <template #modal-footer>
      <div class="row w-100">
        <div class="col-12 text-center">
          <button
            v-show="cancel"
            type="button"
            class="btn btn-gray mr-3"
            data-dismiss="modal"
            @click="onCancel"
          >
            <span class="font-khand font-weight-bold">{{ cancelText }}</span>
          </button>
          <button
            v-show="repeat"
            type="button"
            class="btn btn-gray mr-3"
            data-dismiss="modal"
            @click="onRepeat"
          >
            <span class="font-khand font-weight-bold">{{ repeatText }}</span>
          </button>
          <button
            v-show="ok"
            type="button"
            class="btn btn-success"
            @click="onOk"
          >
            <span class="font-khand font-weight-bold">{{ okText }}</span>
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import modal from "../../mixins/modal";

  export default {
    name: "Messagebox",
    props: {
      ok: {
        type: Boolean,
        default: false,
      },
      cancel: {
        type: Boolean,
        default: true,
      },
      repeat: {
        type: Boolean,
        default: false,
      },
      okText: {
        type: String,
        default: "OK",
      },
      cancelText: {
        type: String,
        default: "Abbrechen",
      },
      repeatText: {
        type: String,
        default: "Wiederholen",
      },
      text: {
        type: String,
        default: null,
      },
      size: {
        type: String,
        default: "lg"
      }
    },
    mixins: [modal],
    data() {
      return {};
    },
    methods: {
      onCancel() {
        this.hide();
        this.$emit("cancel");
      },
      onOk() {
        this.hide();
        this.$emit("ok");
      },
      onRepeat() {
        this.hide();
        this.$emit("repeat");
      },
    },
  };
</script>
