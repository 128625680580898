import {
  SET_PROGRAMM,
  SET_GESTARTET,
  SET_BEENDET,
  SET_MASCHINENZEITEN,
  SET_BEDIENERZEITEN,
  CLEAR_PROGRAMM,
  REMOVE_BILD,
  REMOVE_BEDIENERZEIT,
  REMOVE_MASCHINENZEIT
} from "./mutations.type"
import { LADE_PROGRAMM, STARTEN } from "./actions.type"

import programme from "../../api/programme"

import _ from 'lodash';
import { ADD_BILD } from './../vorgang/mutations.type';

export default {
  namespaced: true,
  state: {
    id: null,
    programm: null,
    gestartet: null,
    beendet: null,
    maschinenzeiten: [],
    bedienerzeiten: [],
    bilder: []
  },
  mutations: {
    [SET_PROGRAMM](state, val) {
      state.programm = val;
      state.id = val.id;
      state.gestartet = val.gestartet;
      state.beendet = val.beendet;
      state.maschinenzeiten = val.maschinenzeiten;
      state.bedienerzeiten = val.bedienerzeiten;
      state.bilder = val.vorgang.bilder;
    },
    [SET_GESTARTET](state, val) {
      state.gestartet = val
    },
    [SET_BEENDET](state, val) {
      state.beendet = val
    },
    [SET_MASCHINENZEITEN](state, val) {
      state.maschinenzeiten = val;
    },
    [SET_BEDIENERZEITEN](state, val) {
      state.bedienerzeiten = val;
    },
    [CLEAR_PROGRAMM](state) {
      state.id = null;
      state.programm = null;
      state.gestartet = null;
      state.beendet = null;
      state.maschinenzeiten = [];
      state.bedienerzeiten = [];
      state.bilder = [];
    },
    [ADD_BILD](state, val){
      state.bilder.push(val)
    },
    [REMOVE_BILD](state, val) {
      state.bilder = state.bilder.filter(b => b.id !== val)
    },
    [REMOVE_BEDIENERZEIT](state, val) {
      state.bedienerzeiten = state.bedienerzeiten.filter(zb => zb.id !== val)
    },
    [REMOVE_MASCHINENZEIT](state, val) {
      state.maschinenzeiten = state.maschinenzeiten.filter(pz => pz.id !== val)
    },
  },
  actions: {
    async [LADE_PROGRAMM](context, id) {
      let response = await programme.getById(id);
      context.commit(SET_PROGRAMM, response.data)
    },
    async [STARTEN](context) {
      let response = await programme.starten(context.state.id);
      context.commit(SET_GESTARTET, response.data.gestartet)
      context.commit(SET_BEENDET, response.data.beendet)
    }
  },
  getters: {
    zeit_maschine_ist: state => _.sumBy(state.maschinenzeiten, ms => ms.laufzeit_maschine),
    zeit_bediener_ist: state => _.sumBy(state.bedienerzeiten, bz => bz.zeit)
  }
}
