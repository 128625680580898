import store from "../store"

export default {
  install: function(Vue) {
    Vue.mixin({
      computed: {
        berechtigungen() {
          return store.state.berechtigungen;
        },
        konstruktionWrite() {
          return this.berechtigungen?.vorgaenge_konstruktion?.write || false
        }
      }
    })
  }
}
