import axios from "axios"

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})


api.interceptors.response.use(
  response => response,
  error => {
    // Benutzer ist niocht authentifiziert
    if (error.response.status === 401) {
      let loginPath = `${process.env.VUE_APP_BASE_URL}login`

      if (window.location.pathname != loginPath) {
        const params = new URLSearchParams()
        params.append("redir", window.location.href);
        //router.push( { name: 'login', query: { 'redir': window.location.href }})
        window.location.href = `${loginPath}?${params.toString()}`
      }
    }

    return Promise.reject(error);
  })


export default api
