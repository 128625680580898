
import vorgang from "../../api/vorgaenge"

import {
  SET_VORGANG,
  SET_ZEIT_KONSTRUKTION_SOLL,
  SET_ZEIT_PROGRAMMIERUNG_SOLL,
  SET_ZEIT_FERTIGUNG_SOLL,
  SET_ZEIT_MASCHINE_SOLL,
  SET_ZEIT_WERKBANK_SOLL,
  SET_ZEITPUNKT_FERTIGUNG_BEENDET,
  SET_ZEITPUNKT_FERTIGUNG_GESTARTET,
  SET_ZEITPUNKT_KONSTRUKTION_BEENDET,
  SET_ZEITPUNKT_KONSTRUKTION_GESTARTET,
  SET_NACHARBEITUNG_NOTWENDIG,
  SET_ZEITBUCHUNGEN,
  SET_PROGRAMMZEITEN,
  SET_FREMDLEISTUNGEN,
  SET_TEILENUMMER,
  SET_TEILEBEZEICHNUNG,
  SET_VORGANGSTYP,
  SET_WERKZEUGNUMMER,
  SET_WERKZEUGBEZEICHNUNG,
  SET_KOSTENSTELLE,
  SET_KONSTRUKTEUR,
  SET_TERMIN,
  SET_VORGANGSUNTERTYP,
  CLEAR_VORGANG,
  ADD_BILD,
  ADD_ZEICHNUNG,
  REMOVE_BILD,
  REMOVE_ZEITBUCHUNG,
  REMOVE_PROGRAMMZEIT,
  REMOVE_FREMDLEISTUNG,
  REMOVE_ZEICHNUNG,
  SET_ZEIT_VORFERTIGUNG_SOLL,
  SET_ZEIT_WERKZEUGBAU_SOLL,
  SET_KONSTRUKTION_EXTERN,
  SET_FERTIGUNG_EXTERN
} from "./mutations.type"
import {
  STARTE_KONSTRUKTION,
  LADE_VORGANG,
  KALKULATION_SPEICHERN,
  BEENDE_KONSTRUKTION,
  STARTE_FERTIGUNG,
  BEENDE_FERTIGUNG,
  RESET,
  UPDATE_VORGANG
} from "./actions.type"
import vorgaenge from "../../api/vorgaenge"
import schritt from "../../models/vorgangsschritt"
import _ from 'lodash';
import { DateTime } from "luxon";

export default {
  namespaced: true,
  state: {
    vorgang: null,
    id: null,
    active: true,
    erprobung_bestanden: false,
    konstrukteurId: null,
    konstrukteur: null,
    kostenstelleId: null,
    kostenstelle: null,
    kundenauftrag: true,
    nacharbeitung_notwendig: null,
    vorgangsuntertypId: null,
    vorgangsuntertyp: null,
    statusId: null,
    status: null,
    teilebezeichnung: null,
    teilenummer: null,
    termin: null,
    vorgangstypId: null,
    vorgangstyp: null,
    werkezeug_anzahl_gravuren_ist: 0,
    werkezeug_keramische_kerne_ist: 0,
    werkezeug_sonstiges: null,
    werkezeug_trennungsspruenge_ist: 0,
    werkezeug_wasserloeslische_kerne_ist: 0,
    werkzeug_anzahl_gravuren_soll: 0,
    werkzeug_automat_gross: false,
    werkzeug_automat_klein: false,
    werkzeug_hand: false,
    werkzeug_keramische_kerne_soll: 0,
    werkzeug_kws: false,
    werkzeug_schieber_stecker_einlegeteile_ist: 0,
    werkzeug_schieber_stecker_einlegeteile_soll: 0,
    werkzeug_sonstige: false,
    werkzeug_sonstige_text: null,
    werkzeug_trennungsspruenge_soll: 0,
    werkzeug_wasserloeslische_kerne_soll: 0,
    werkzeugbezeichnung: null,
    werkzeugnummer: null,
    zeit_fertigung_soll: 0,
    zeit_vorfertigung_soll: 0,
    zeit_konstruktion_soll: 0,
    zeit_maschine_ist: 0,
    zeit_maschine_soll: 0,
    zeit_programmierung_soll: 0,
    zeit_werkbank_soll: 0,
    zeit_werkzeugbau_soll: 0,
    konstruktion_extern: false,
    fertigung_extern: false,
    zeitpunkt_konstruktion_gestartet: null,
    zeitpunkt_konstruktion_beendet: null,
    zeitpunkt_fertigung_gestartet: null,
    zeitpunkt_fertigung_beendet: null,
    zeitbuchungen: [],
    programmzeiten: [],
    fremdleistungen: [],
    zeichnungen: [],
    bilder: [],
    anzahl_erprobungen: 0,
    kosten_konstruktion_soll: 0,
    kosten_werkzeugbau_intern_soll: 0,
    kosten_maschine_soll: 0,
  },
  mutations: {
    [SET_TEILENUMMER](state,val) {
      state.teilenummer = val
    },
    [SET_TEILEBEZEICHNUNG](state,val) {
      state.teilebezeichnung = val
    },
    [SET_VORGANGSTYP](state,val) {
      state.vorgangstypId = val
    },
    [SET_VORGANGSUNTERTYP](state,val) {
      state.vorgangsuntertypId = val
    },
    [SET_WERKZEUGNUMMER](state,val) {
      state.werkzeugnummer = val
    },
    [SET_WERKZEUGBEZEICHNUNG](state,val) {
      state.werkzeugbezeichnung = val
    },
    [SET_KOSTENSTELLE](state,val) {
      state.kostenstelleId = val
    },
    [SET_KONSTRUKTEUR](state,val) {
      state.konstrukteurId = val
    },
    [SET_TERMIN](state,val) {
      state.termin = val
    },
    [SET_ZEIT_KONSTRUKTION_SOLL](state,val) {
      state.zeit_konstruktion_soll = val;
    },
    [SET_ZEIT_VORFERTIGUNG_SOLL](state,val) {
      state.zeit_vorfertigung_soll = val;
    },
    [SET_ZEIT_PROGRAMMIERUNG_SOLL](state,val) {
      state.zeit_programmierung_soll = val;
    },
    [SET_ZEIT_FERTIGUNG_SOLL](state,val) {
      state.zeit_fertigung_soll = val;
    },
    [SET_ZEIT_MASCHINE_SOLL](state,val) {
      state.zeit_maschine_soll = val;
    },
    [SET_ZEIT_WERKBANK_SOLL](state,val) {
      state.zeit_werkbank_soll = val;
    },
    [SET_ZEIT_WERKZEUGBAU_SOLL](state,val) {
      state.zeit_werkzeugbau_soll = val;
    },
    [SET_KONSTRUKTION_EXTERN](state, val) {
      state.konstruktion_extern = val;
    },
    [SET_FERTIGUNG_EXTERN](state, val) {
      state.fertigung_extern = val;
    },
    [SET_ZEITBUCHUNGEN](state, val) {
      state.zeitbuchungen = _.reverse(_.sortBy(val, zb => zb.zeitstempel).map((zb, index) =>  {
        zb.nummer = index + 1
        return zb;
      }));
    },
    [REMOVE_ZEITBUCHUNG](state, val) {
      state.zeitbuchungen = state.zeitbuchungen.filter(zb => zb.id !== val)
    },
    [SET_PROGRAMMZEITEN](state, val) {
      state.programmzeiten = _.reverse(_.sortBy(val, zb => zb.zeitstempel).map((zb, index) =>  {
        zb.nummer = index + 1
        return zb;
      }));
    },
    [REMOVE_PROGRAMMZEIT](state, val) {
      state.programmzeiten = state.programmzeiten.filter(pz => pz.id !== val)
    },
    [SET_FREMDLEISTUNGEN](state, val) {
      state.fremdleistungen = _.reverse(_.sortBy(val, fl => fl.zeitstempel).map((fl, index) =>  {
        fl.nummer = index + 1
        return fl;
      }));
    },
    [REMOVE_FREMDLEISTUNG](state, val) {
      state.fremdleistungen = state.fremdleistungen.filter(fl => fl.id !== val)
    },
    [SET_ZEITPUNKT_KONSTRUKTION_GESTARTET](state, val) { state.zeitpunkt_konstruktion_gestartet = val },
    [SET_ZEITPUNKT_FERTIGUNG_GESTARTET](state, val) { state.zeitpunkt_fertigung_gestartet = val },
    [SET_ZEITPUNKT_KONSTRUKTION_BEENDET](state, val) { state.zeitpunkt_konstruktion_beendet = val },
    [SET_ZEITPUNKT_FERTIGUNG_BEENDET](state, val) { state.zeitpunkt_fertigung_beendet = val },
    [SET_NACHARBEITUNG_NOTWENDIG](state, val) { state.nacharbeitung_notwendig = val },
    [SET_VORGANG](state, val) {
      state.vorgang = val;
      state.id = val.id;
      state.active = val.active;
      state.erprobung_bestanden = val.erprobung_bestanden;
      state.konstrukteurId = val.konstrukteurId;
      state.konstrukteur = val.konstrukteur;
      state.kostenstelleId = val.kostenstelleId;
      state.kostenstelle = val.kostenstelle;
      state.kundenauftrag = val.kundenauftrag;
      state.nacharbeitung_notwendig = val.nacharbeitung_notwendig;
      state.vorgangsuntertypId = val.vorgangsuntertypId;
      state.vorgangsuntertyp = val.vorgangsuntertyp;
      state.statusId = val.statusId;
      state.teilebezeichnung = val.teilebezeichnung;
      state.teilenummer = val.teilenummer;
      state.termin = val.termin;
      state.vorgangstypId = val.vorgangstypId;
      state.vorgangstyp = val.vorgangstyp;
      state.werkezeug_anzahl_gravuren_ist = val.werkezeug_anzahl_gravuren_ist;
      state.werkezeug_keramische_kerne_ist = val.werkezeug_keramische_kerne_ist;
      state.werkezeug_sonstiges = val.werkezeug_sonstiges;
      state.werkezeug_trennungsspruenge_ist = val.werkezeug_trennungsspruenge_ist;
      state.werkezeug_wasserloeslische_kerne_ist = val.werkezeug_wasserloeslische_kerne_ist;
      state.werkzeug_anzahl_gravuren_soll = val.werkzeug_anzahl_gravuren_soll;
      state.werkzeug_automat_gross = val.werkzeug_automat_gross;
      state.werkzeug_automat_klein = val.werkzeug_automat_klein;
      state.werkzeug_hand = val.werkzeug_hand;
      state.werkzeug_keramische_kerne_soll = val.werkzeug_keramische_kerne_soll;
      state.werkzeug_kws = val.werkzeug_kws;
      state.werkzeug_schieber_stecker_einlegeteile_ist = val.werkzeug_schieber_stecker_einlegeteile_ist;
      state.werkzeug_schieber_stecker_einlegeteile_soll = val.werkzeug_schieber_stecker_einlegeteile_soll;
      state.werkzeug_sonstige = val.werkzeug_sonstige;
      state.werkzeug_sonstige_text = val.werkzeug_sonstige_text;
      state.werkzeug_trennungsspruenge_soll = val.werkzeug_trennungsspruenge_soll;
      state.werkzeug_wasserloeslische_kerne_soll = val.werkzeug_wasserloeslische_kerne_soll;
      state.werkzeugbezeichnung = val.werkzeugbezeichnung;
      state.werkzeugnummer = val.werkzeugnummer;
      state.zeit_fertigung_soll = val.zeit_fertigung_soll;
      state.zeit_vorfertigung_soll = val.zeit_vorfertigung_soll;
      state.zeit_konstruktion_soll = val.zeit_konstruktion_soll;
      state.zeit_maschine_ist = val.zeit_maschine_ist;
      state.zeit_maschine_soll = val.zeit_maschine_soll;
      state.zeit_programmierung_soll = val.zeit_programmierung_soll;
      state.zeit_werkbank_soll = val.zeit_werkbank_soll;
      state.zeit_werkzeugbau_soll= val.zeit_werkzeugbau_soll;

      state.konstruktion_extern= val.konstruktion_extern;
      state.fertigung_extern= val.fertigung_extern;

      state.zeitpunkt_konstruktion_gestartet = val.zeitpunkt_konstruktion_gestartet;
      state.zeitpunkt_konstruktion_beendet = val.zeitpunkt_konstruktion_beendet;
      state.zeitpunkt_fertigung_gestartet = val.zeitpunkt_fertigung_gestartet;
      state.zeitpunkt_fertigung_beendet = val.zeitpunkt_fertigung_beendet;

      state.zeitbuchungen = _.reverse(_.sortBy(val.zeitbuchungen, zb => zb.zeitstempel))
      state.programmzeiten = _.reverse(_.sortBy(val.programmzeiten, zb => zb.zeitstempel))
      state.fremdleistungen = _.reverse(_.sortBy(val.fremdleistungen, zb => zb.zeitstempel))

      state.zeichnungen = val.zeichnungen;
      state.bilder = val.bilder;
      state.anzahl_erprobungen = val.anzahl_erprobungen

      state.kosten_konstruktion_soll = val.kosten_konstruktion_soll;
      state.kosten_werkzeugbau_intern_soll = val.kosten_werkzeugbau_intern_soll;
      state.kosten_maschine_soll = val.kosten_maschine_soll;
    },
    [CLEAR_VORGANG](state) {
      state.vorgang= null;
      state.id= null;
      state.active= true;
      state.erprobung_bestanden= false;
      state.konstrukteurId= null;
      state.konstrukteur= null;
      state.kostenstelleId= null;
      state.kostenstelle= null;
      state.kundenauftrag= true;
      state.nacharbeitung_notwendig= null;
      state.vorgangsuntertypId= null;
      state.vorgangsuntertyp= null;
      state.statusId= null;
      state.status= null;
      state.teilebezeichnung= null;
      state.teilenummer= null;
      state.termin= null;
      state.vorgangstypId= null;
      state.vorgangstyp= null;
      state.werkezeug_anzahl_gravuren_ist= 0;
      state.werkezeug_keramische_kerne_ist= 0;
      state.werkezeug_sonstiges= null;
      state.werkezeug_trennungsspruenge_ist= 0;
      state.werkezeug_wasserloeslische_kerne_ist= 0;
      state.werkzeug_anzahl_gravuren_soll= 0;
      state.werkzeug_automat_gross= false;
      state.werkzeug_automat_klein= false;
      state.werkzeug_hand= false;
      state.werkzeug_keramische_kerne_soll= 0;
      state.werkzeug_kws= false;
      state.werkzeug_schieber_stecker_einlegeteile_ist= 0;
      state.werkzeug_schieber_stecker_einlegeteile_soll= 0;
      state.werkzeug_sonstige= false;
      state.werkzeug_sonstige_text= null;
      state.werkzeug_trennungsspruenge_soll= 0;
      state.werkzeug_wasserloeslische_kerne_soll= 0;
      state.werkzeugbezeichnung= null;
      state.werkzeugnummer= null;
      state.zeit_fertigung_soll= 0;
      state.zeit_vorfertigung_soll= 0;
      state.zeit_konstruktion_soll= 0;
      state.zeit_maschine_ist= 0;
      state.zeit_maschine_soll= 0;
      state.zeit_programmierung_soll= 0;
      state.zeit_werkbank_soll= 0;
      state.zeit_werkzeugbau_soll= 0;
      state.konstruktion_extern= false;
      state.fertigung_extern= false;
      state.zeitpunkt_konstruktion_gestartet= null;
      state.zeitpunkt_konstruktion_beendet= null;
      state.zeitpunkt_fertigung_gestartet= null;
      state.zeitpunkt_fertigung_beendet= null;
      state.zeitbuchungen= [];
      state.programmzeiten= [];
      state.zeichnungen = [];
      state.bilder = [];
      state.anzahl_erprobungen = 0;
      state.kosten_konstruktion_soll = 0;
      state.kosten_werkzeugbau_intern_soll = 0;
      state.kosten_maschine_soll = 0;
    },
    [ADD_ZEICHNUNG](state, val) {
      state.zeichnungen.push(val)
    },
    [REMOVE_ZEICHNUNG](state, val) {
      state.zeichnungen = state.zeichnungen.filter(z => z.id !== val)
    },
    [ADD_BILD](state, val) {
      state.bilder.push(val)
    },
    [REMOVE_BILD](state, val) {
      state.bilder = state.bilder.filter(b => b.id !== val)
    }
  },
  actions: {
    async [LADE_VORGANG](context, [ id, schritt ]) {
      try {
        let response = await vorgang.getById(id, schritt)
        context.commit(SET_VORGANG, response.data);
      } catch (e) {
        console.log(e)
      }
    },
    async [UPDATE_VORGANG](context) {
      try {
        let response = await vorgang.put(
          context.state.id,
          context.state.vorgangstypId,
          context.state.kostenstelleId,
          context.state.vorgangsuntertypId,
          context.state.kundenauftrag,
          context.state.teilenummer,
          context.state.teilebezeichnung,
          context.state.werkzeugnummer,
          context.state.werkzeugbezeichnung,
          context.state.termin,
          context.state.konstrukteurId
        )

        context.commit(SET_VORGANG, response.data);

      } catch (e) {
        console.log(e)
      }
    },
    async [KALKULATION_SPEICHERN](context) {
      try {
        let response = await vorgang.kalkulationSpeichern(
          context.state.id,
          context.state.zeit_konstruktion_soll,
          context.state.zeit_vorfertigung_soll,
          context.state.zeit_programmierung_soll,
          context.state.zeit_fertigung_soll,
          context.state.zeit_maschine_soll,
          context.state.zeit_werkbank_soll,
          context.state.konstruktion_extern,
          context.state.fertigung_extern,
          )
        context.commit(SET_VORGANG, response.data);
      } catch (e) {
        console.log(e)
      }
    },
    async [STARTE_KONSTRUKTION](context) {
      try {
        let response = await vorgaenge.startekonstrukion(context.state.id)
        context.commit(SET_ZEITPUNKT_KONSTRUKTION_GESTARTET, response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async [BEENDE_KONSTRUKTION](context) {
      try {
        let response = await vorgaenge.beendekonstrukion(context.state.id)
        context.commit(SET_ZEITPUNKT_KONSTRUKTION_BEENDET, response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async [STARTE_FERTIGUNG](context) {
      try {
        let response = await vorgaenge.starteFertigung(context.state.id)
        context.commit(SET_ZEITPUNKT_FERTIGUNG_GESTARTET, response.data)
      } catch (e) {
        console.log(e)
      }
    },
    async [BEENDE_FERTIGUNG](context) {
      try {
        let response = await vorgaenge.beendeFertigung(context.state.id)
        context.commit(SET_ZEITPUNKT_FERTIGUNG_BEENDET, response.data)
        context.commit(SET_NACHARBEITUNG_NOTWENDIG, false)
      } catch (e) {
        console.log(e)
      }
    },
    [RESET](context) {
      context.commit(SET_VORGANG, context.state.vorgang)
    }
  },
  getters: {
    konstruktionGestartet: state => state.zeitpunkt_konstruktion_gestartet !== null,
    konstruktionBeendet: state => state.zeitpunkt_konstruktion_beendet !== null,
    fertigungGestartet: state => state.zeitpunkt_fertigung_gestartet !== null,
    fertigungBeendet: state => state.zeitpunkt_fertigung_beendet !== null,
    konstruktionsZeitbuchungen: state => state.zeitbuchungen.filter(zb => zb.schrittId === schritt.konstruktion),
    konstruktionsFremdleistungen: state => state.fremdleistungen.filter(fl => fl.schrittId === schritt.konstruktion),
    programmierungZeitbuchungen: state => state.zeitbuchungen.filter(zb => zb.schrittId === schritt.programmierung),
    programmierungProgrammzeiten: state => state.programmzeiten.filter(zb => zb.schrittId === schritt.programmierung),
    fertigungZeitbuchungen: state => state.zeitbuchungen.filter(zb => zb.schrittId === schritt.fertigung),
    vorfertigungZeitbuchungen: state => state.zeitbuchungen.filter(zb => zb.schrittId === schritt.vorfertigung),
    fertigungFremdleistungen: state => state.fremdleistungen.filter(zb => zb.schrittId === schritt.fertigung),
    fertigungProgrammzeiten: state => state.programmzeiten.filter(zb => zb.schrittId === schritt.fertigung),
    werkbankZeitbuchungen: state => state.zeitbuchungen.filter(zb => zb.schrittId === schritt.werkbank),
    zeit_konstruktion_ist: state => _.sumBy(state.zeitbuchungen.filter(zb => zb.schrittId === schritt.konstruktion), zb => zb.zeit || 0),
    zeit_vorfertigung_ist: state => _.sumBy(state.zeitbuchungen.filter(zb => zb.schrittId === schritt.vorfertigung), zb => zb.zeit || 0),
    zeit_programmieren_ist: state => _.sumBy(state.zeitbuchungen.filter(zb => zb.schrittId === schritt.programmierung), zb => zb.zeit || 0),
    zeit_fertigung_ist: state => _.sumBy(state.zeitbuchungen.filter(zb => zb.schrittId === schritt.fertigung), zb => zb.zeit || 0),
    zeit_maschine_ist: state => _.sumBy(state.programmzeiten.filter(zb => zb.schrittId === schritt.fertigung), zb => zb.laufzeit_maschine || 0),
    zeit_werkbank_ist: state => _.sumBy(state.zeitbuchungen.filter(zb => zb.schrittId === schritt.werkbank), zb => zb.zeit || 0),
    zeit_werkzeugbau_ist: state => _.sumBy(state.zeitbuchungen.filter(zb => zb.schrittId !== schritt.konstruktion), zb => zb.zeit || 0),
    tage_bis_termin: state => {
      if(state.termin) {
        let termin = DateTime.fromMillis(this.termin).startOf('day');
        return DateTime.local().diff(termin, 'days').toObject().days;
      } else {
        return null
      }
    },
    vorgangUeberzogen: state => {
      if(state.zeitpunkt_fertigung_beendet && state.termin) {
        let beendetAm = DateTime.fromMillis(state.zeitpunkt_fertigung_beendet).startOf('day');
        let termin = DateTime.fromMillis(state.termin).startOf('day');
        return beendetAm.diff(termin, 'days').toObject().days;
      } else {
        return 0
      }
    }
  }
}
