import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

import organisationsbereiche from "../static/organisationsbereiche";

import windowModul from "./window";
import maschine from "./maschine";
import vorgang from "./vorgang";
import programm from "./programm";
import kategorien from "../api/kategorien";
import mitarbeiter from "../api/mitarbeiter";
import permissions from "../api/permissions";
import mitarbeiterModal from "../models/mitarbeiter"

import server from "../server";

import _ from 'lodash';

import {
  SET_KOSTENSTELLEN,
  SET_MASCHINENTYPEN,
  SET_MITARBEITER,
  SET_VORGANGSSCHRITTE,
  SET_VORGANGSSTATI,
  SET_VORGANGSTYPEN,
  SET_STAMMDATEN_GELADEN,
  SET_ANGEMELDETER_MITARBEITER,
  SET_BERECHTIGUNGEN,
  UPDATE_KOSTENSTELLE
} from "./mutations.type";

import { LADE_KATEGORIEN, LADE_BERECHTIGUNGEN, LADE_USER, LOGOUT } from "./actions.type"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    kostenstellen: [],
    vorgangstypen: [],
    maschinentypen: [],
    vorgangsstati: [],
    vorgangsschritte: [],
    mitarbeiter: [],
    angemeldeterMitarbeiter: null,
    stammdatenGeladen: false,
    berechtigungen: null
  },
  mutations: {
    [SET_KOSTENSTELLEN](state, val) {
      state.kostenstellen = val
    },
    [SET_VORGANGSTYPEN](state, val) {
      state.vorgangstypen = val
    },
    [SET_MASCHINENTYPEN](state, val) {
      state.maschinentypen = val
    },
    [SET_VORGANGSSTATI](state, val) {
      state.vorgangsstati = val
    },
    [SET_VORGANGSSCHRITTE](state, val) {
      state.vorgangsschritte = val
    },
    [SET_MITARBEITER](state, val) {
      state.mitarbeiter = val;
    },
    [SET_STAMMDATEN_GELADEN](state, val) {
      state.stammdatenGeladen = val
    },
    [SET_ANGEMELDETER_MITARBEITER](state, val) {
      state.angemeldeterMitarbeiter = val;
    },
    [SET_BERECHTIGUNGEN](state, val) {
      state.berechtigungen = val;
    },
    [UPDATE_KOSTENSTELLE](state, val){
      const indexKostenstelle = state.kostenstellen.findIndex(ks => ks.id === val.id );
      console.log("UPDATE_KOSTENSTELLE:i: " + indexKostenstelle);
      (indexKostenstelle!== -1)?state.kostenstellen[indexKostenstelle] = val:null;
    }
  },
  actions: {
    async [LADE_KATEGORIEN](context) {
      context.commit(SET_STAMMDATEN_GELADEN, false)
      try {
        let response = await kategorien.get();
        let data = response.data;
        context.commit(SET_KOSTENSTELLEN, data.kostenstellen)
        context.commit(SET_VORGANGSSCHRITTE, data.vorgangsschritte)
        context.commit(SET_VORGANGSSTATI, data.vorgangsstati)
        context.commit(SET_VORGANGSTYPEN, data.vorgangstypen)
        context.commit(SET_MASCHINENTYPEN, data.maschinentypen)

        let m = (await mitarbeiter.get()).data;
        context.commit(SET_MITARBEITER, m)

        let berechtigungen = (await permissions.getPermissions(context.state.angemeldeterMitarbeiter.id)).data;
        context.commit(SET_BERECHTIGUNGEN, berechtigungen)
      } finally {
        context.commit(SET_STAMMDATEN_GELADEN, true)
      }
    },
    async [LADE_USER](context) {
      context.commit(SET_ANGEMELDETER_MITARBEITER, (await mitarbeiter.getAngemeldetenMitarbeiter()).data)
    },
    async [LADE_BERECHTIGUNGEN](context, userId) {
      try {
        let response = await permissions.getPermissions(userId);
        context.commit(SET_BERECHTIGUNGEN, response.data)
      } finally {
        context.commit(SET_STAMMDATEN_GELADEN, true)
      }
    },
    async [LOGOUT](context) {
      let logoutUrl = process.env.NODE_ENV === "development" ? `${server.url}/logout/` : '/logout/'
      await axios.get(logoutUrl);
      context.commit(SET_STAMMDATEN_GELADEN, false)
      context.commit(SET_BERECHTIGUNGEN, null)
      context.commit(SET_ANGEMELDETER_MITARBEITER, null)
    }
  },
  modules: {
    window: windowModul,
    vorgang: vorgang,
    maschine: maschine,
    programm: programm
  },
  getters: {
    konstrukteure: state => {
      return state.mitarbeiter.filter(m => m.organisationsbereiche.some(ob => ob.id === organisationsbereiche.konstruktion))
    },
    werker: state => {
      return state.mitarbeiter.filter(m => m.organisationsbereiche.some(ob => ob.id === organisationsbereiche.werker))
    },
    maschinen: state => _.flatMap(state.maschinentypen, mt => mt.maschinen),
    darfKonstruktionBetreten: state => state.angemeldeterMitarbeiter && mitarbeiterModal.istKontrukteur(state.angemeldeterMitarbeiter)
  }
})
